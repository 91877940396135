.login {
  height: 100vh;
  background: url("../../images/login-bg.png") no-repeat  85% center ;
  background-size: 500px;
  background-color: #2845e5;
  position: relative;
}

.login .login-form {
  position: absolute;
  top: 180px;
  left: 16%;
  width: 450px;
}
.login .login-form input, .login .login-form button {
  font-size: 16px;
}
.login .login-form .ant-form {
  width: 300px;
  padding: 36px;
  margin: 0 auto;
}

.login .login-form .login-logo .login-name {
  text-align: center;
  font-size: 70px;
  color: #fff;
}

.login-form-forgot {
  float: right;
}

.login .ant-form-item-control{
  text-align: center;
}
.login-form-link {
  color: #fff
}