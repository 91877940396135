.setting{margin: 0px; border-radius: 10px;background-color: #fff; padding: 10px;}
.setting .wrapCabinet{display: flex;justify-content: space-around; flex-wrap: wrap;width: 640px; margin: 0 auto;}
.setting .cabinet{border: 2px #333 solid; border-radius: 10px;padding: 5px 10px;margin-bottom: 10px;width: 150px;text-align: center; }
.fontSize18{font-size: 18px;}
.fontSize16{font-size: 16px;}

/* 清除或隐藏antd数字输入框InputNumber最右侧上下箭头 */
.ant-input-number-handler-wrap {
    display: none
}
.ant-input-number {width: 100%;}